import useUtils from '@hooks/useUtils';

import { useMoralis } from 'react-moralis';
import { useEffect } from 'react';
import ModalCom from '@components/modals/ModalCom';
import useUser from '@hooks/useUser';
import { useAppDispatch, useAppSelector } from '@store/store';
import { hideLoginPopup } from '@store/slices/connectSlice';
import LoginContent from './LoginContent';

function LoginPopup() {
  const { showLoginPopup } = useAppSelector((state) => state.connect);
  const dispatch = useAppDispatch();
  const { incompatibleChain } = useUtils();
  const { currentUser } = useUser();
  const { isWeb3Enabled } = useMoralis();

  useEffect(() => {
    if (isWeb3Enabled && currentUser && !incompatibleChain) {
      dispatch(hideLoginPopup());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, incompatibleChain, isWeb3Enabled]);

  return (
    <ModalCom onClose={() => dispatch(hideLoginPopup())} open={showLoginPopup}>
      <LoginContent />
    </ModalCom>
  );
}

export default LoginPopup;
