import { Box, Typography, Button } from '@mui/material';
import { HeaderModal, CloseBtn, FieldStyled } from '@styles/modal/SModal';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  useGetWhitelistsQuery,
  useLazyGetWhitelistsQuery,
} from '@store/api/whitelistsApi';
import useWhitelists from '@hooks/useWhitelists';
import { toRem } from '@utils/functions';
import ModalCom from '../ModalCom';

interface Props {
  show: boolean;
  closeModal: () => void;
}

interface WhitelistsInitialValues {
  whitelists: string;
}

function AddWhitelistModal({ show, closeModal }: Props) {
  const { data: whitelistsData } = useGetWhitelistsQuery({});
  const { updateWhitelists } = useWhitelists();
  const initialValues: WhitelistsInitialValues = {
    whitelists: whitelistsData || '',
  };

  const validationSchema = () =>
    yup.object().shape({
      whitelists: yup.string().required(),
    });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await updateWhitelists(values.whitelists);
    },
  });
  const {
    values,
    submitForm,
    handleChange,
    handleBlur,
    errors,
    touched,
    resetForm,
  } = formik;

  const handleSubmit = async () => {
    await submitForm();
    resetForm();
  };

  return (
    <ModalCom
      open={show}
      onClose={() => {
        resetForm();
        closeModal();
      }}
    >
      <Box>
        <HeaderModal>
          <Typography variant="h2">Add Whitelists</Typography>

          <CloseBtn
            onClick={() => {
              closeModal();
              // resetForm();
            }}
          >
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>

        <Box p={2}>
          <FieldStyled
            fullWidth
            id="whitelists"
            name="whitelists"
            label="Whitelisted Addresses"
            multiline
            rows={10}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.whitelists.toLowerCase()}
            sx={{ flex: 1, minHeight: '90px' }}
            helperText={touched.whitelists ? errors.whitelists : ''}
            error={Boolean(errors.whitelists) && touched.whitelists}
          />

          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            sx={{ mt: toRem(12) }}
            fullWidth
          >
            Update Whitelists
          </Button>
        </Box>
      </Box>
    </ModalCom>
  );
}

export default AddWhitelistModal;
