import { Typography, Box } from '@mui/material';
import { formatNr } from '@utils/functions';
import ModalCom from '@components/modals/ModalCom';
import { ModalDetailsBox, ModalH6Text } from '@styles/modal/SModal';
import useStyles from '@hooks/useStyles';
import Lottie from 'lottie-react';
import LogoLoadingAnimation from '@animations/lv-loading-animation.json';

interface Props {
  open: boolean;
  onClose: () => void;
  tokenPrice: number;
}

function TxInvestmentConfirmationModal({ open, onClose, tokenPrice }: Props) {
  const { border1pxPrimaryLight } = useStyles();

  return (
    <ModalCom open={open} onClose={onClose}>
      <Box
        sx={{
          p: '36px 68px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ maxWidth: '76px', width: '100%' }}>
          <Lottie
            animationData={LogoLoadingAnimation}
            loop
            style={{ marginBottom: '24px' }}
          />
        </Box>
        <Typography variant="h4" sx={{ mb: '10px' }}>
          Waiting for your confirmation
        </Typography>
        <ModalH6Text sx={{ mb: '12px' }}>
          Please confirm this transaction in your wallet
        </ModalH6Text>
        <ModalDetailsBox sx={{ mb: '36px' }}>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                borderRight: border1pxPrimaryLight,
                p: '15px',
                minWidth: { xs: '110px', sm: '124px' },
              }}
            >
              <ModalH6Text>Token Price</ModalH6Text>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                gap: '10px',
                p: '15px',
              }}
            >
              <Box
                sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(tokenPrice, true)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ModalDetailsBox>
      </Box>
    </ModalCom>
  );
}
export default TxInvestmentConfirmationModal;
